import { dateFormattedFilter } from '@/shared/filters/ToDateFormatted'
import StudentRepository from '@/shared/http/repositories/socialProject/student'
import { loading, toast } from '@/shared/utils/UIHelper'
import { IsSuccessCode } from '@/shared/utils/API'

export default {
  template: 'reportmixin',
  data: () => ({
    dataChart: null,
    district: '',
    optionsChart: null,
    search: '',
    sendingSearch: false,
    sendingSearchChart: false,
    students: [],
    totalStudents: 0,
    totalStudentsModality: 0,
    isLoadingStudents: false,
    perPage: 15,
    currentPage: 1,
    emptyText: 'Não há registros',
    fields: [
      {
        key: 'code',
        label: 'CÓDIGO',
        class: 'left-header-border text-center'
      },
      {
        key: 'name',
        label: 'NOME',
        class: 'middle-header text-center'
      },
      {
        key: 'gender',
        label: 'SEXO',
        class: 'middle-header text-center'
      },
      {
        key: 'age',
        label: 'IDADE',
        class: 'middle-header text-center'
      },
      {
        key: 'socialEntityName',
        label: 'ENTIDADE',
        class: 'middle-header text-center'
      },
      {
        key: 'modalityName',
        label: 'MODALIDADE',
        class: 'middle-header text-center'
      },
      {
        key: 'localTrainingName',
        label: 'LOCAL DE TREINAMENTO',
        class: 'middle-header text-center'
      },
      {
        key: 'healthPlanName',
        label: 'PLANO DE SAÚDE',
        class: 'middle-header text-center'
      },
      {
        key: 'schoolName',
        label: 'ESCOLA',
        class: 'middle-header text-center'
      },
      {
        key: 'familyIncome',
        label: 'RENDA FAMILIAR',
        class: 'middle-header text-center'
      },
      {
        key: 'familyBag',
        label: 'BOLSA FAMÍLIA',
        class: 'middle-header text-center'
      },
      {
        key: 'motherName',
        label: 'NOME - MÃE',
        class: 'middle-header text-center'
      },
      {
        key: 'fatherName',
        label: 'NOME - PAI',
        class: 'middle-header text-center'
      },
      {
        key: 'birthDate',
        label: 'DATA NASCIMENTO',
        class: 'middle-header text-center',
        formatter: dateFormattedFilter
      },
      {
        key: 'status',
        label: 'STATUS',
        class: 'middle-header text-center'
      },
      {
        key: 'yearRegistration',
        label: 'ANO REMATRÍCULA',
        class: 'middle-header text-center'
      },
      {
        key: 'dateLastUpdate',
        label: 'DATA ÚLTIMA ALTERAÇÃO',
        class: 'right-header-border text-center',
        formatter: dateFormattedFilter
      },
    ]
  }),

  computed: {
    rows() {
      return this.students.length
    }
  },

  mounted() {
    this.getTotalStudents()
  },
  
  methods: {
    GetTotalStudents(totalStudents) {
      this.totalStudents = totalStudents
    },
    GetTotalStudentsModality(totalStudents) {
      this.totalStudentsModality = totalStudents
    },
    IsLoadingStudents(value) {
      this.isLoadingStudents = value
    },
    formatDate(date) {
      if (!date) return null
      return this.$options.filters.ToDateFormatted(date)
    },
    mergeItemList() {
      if (this.form.id === null || this.form.id === '00000000-0000-0000-0000-000000000000') {
        this.listEntity.push(this.form)
      } else {
        var index = this.listEntity.findIndex(item => item.id === this.form.id)
        this.listEntity.splice(index, 1, this.form)
      }
    },
    removeItemList() {
      var index = this.listEntity.indexOf(this.selected)
      this.listEntity.splice(index, 1)
    },
    onSearch(field, text) {
      this.sendingSearch = true
      if (this.search !== '') {
        this.students = this.students.filter(list => list[field].toLowerCase().match(text.trim().toLowerCase()))
        this.sendingSearch = false
      } else {
        this.LoadAll(this.entity)
        this.sendingSearch = false
      }
    },
    onClearSearch() {
      this.search = ''
      this.LoadAll(this.entity)
    },
    onSelectType(list, fieldId, fieldName) {
      if (this.form[fieldId]) {
        let type = list.filter(s => s.id === this.form[fieldId])

        if (type.length > 0) {
          this.form[fieldName] = type[0].name
        }
      }
    },
    onGridInactiveItem(item) {
      this.selected = item
      this.showDialogInactive = true
    },
    onCancel() {
      this.showDialogForm = false
    },
    onBackList() {
      this.$router.replace(this.entityListURL)
    },
    getTotalStudents(){
      StudentRepository.GetTotal()
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.totalStudents = res.data.data.count

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar alunos', 'ERRO')
        })
    }
  }
}
