import SocialProjectManager from '../../clients/abdaSocialProjectManager'
import { merge } from 'lodash'

const resourceName = 'reportstudent'

const GetTotalByAge = () => SocialProjectManager.get(`${resourceName}/total/age`)
const GetTotalByFamilyIncome = () => SocialProjectManager.get(`${resourceName}/total/familyincome`)
const GetTotalByGender = () => SocialProjectManager.get(`${resourceName}/total/gender`)
const GetTotalByCompany = () => SocialProjectManager.get(`${resourceName}/total/company`)
const GetTotalBySchool = () => SocialProjectManager.get(`${resourceName}/total/school`)
const GetTotalByModality = () => SocialProjectManager.get(`${resourceName}/total/modality`)
const GetTotalByLocalTraining = () => SocialProjectManager.get(`${resourceName}/total/localtraining`)
const GetTotalByDistrict = () => SocialProjectManager.get(`${resourceName}/total/district`)
const GetStudentsByGender = gender => SocialProjectManager.get(`${resourceName}/gender/${gender}`)

const GetStudentsByAge = age => SocialProjectManager.get(`${resourceName}/age/${age}`)

const GetStudentsByImc = () => SocialProjectManager.get(`${resourceName}/total/imc`)
const GetByStudentImc = imc => SocialProjectManager.get(`${resourceName}/imc/${imc}`)
const GetImcHistoryById = id => SocialProjectManager.get(`${resourceName}/${id}/history/imc`)
const GetStudentMeasurementSearched = (criteria, pagination) => SocialProjectManager.get(`${resourceName}/criteria/measurement`, {
  params: merge(criteria, pagination)
})

const GetTotalBySocialProject = () => SocialProjectManager.get(`${resourceName}/total/social-entity`)
const GetTotalBySocialProjectId = (criteria, pagination) => SocialProjectManager.get(`${resourceName}/criteria`, {
  params: merge(criteria, pagination)
})

const GetStudentsByFamilyIncome = rangeValues => {
  let values = []
  if (rangeValues && rangeValues.indexOf(',') !== -1) {
    const valueSplitted = rangeValues.split(',')
    values = `values=${valueSplitted[0]}&values=${valueSplitted[1]}`
  } else {
    values = `values=${rangeValues}`
  }

  return SocialProjectManager.get(`${resourceName}/familyincome?${values}`)
} 

const GetStudentsBySchool = school => SocialProjectManager.get(`${resourceName}/school/${school}`)
const GetStudentsByModality = modality => SocialProjectManager.get(`${resourceName}/modality/${modality}`)
const GetStudentsByLocalTraining = localtraining => SocialProjectManager.get(`${resourceName}/localtraining/${localtraining}`)
const GetStudentsByDistrict = district => SocialProjectManager.get(`${resourceName}/district/${district}`)
const GetAllStudentAbsent = (criteria, pagination) => SocialProjectManager.get(`${resourceName}/studentabsent`, {
  params: merge(criteria, pagination)
})
const GetAll = () => SocialProjectManager.get(resourceName)
const GetATC = (datestart, dateend, data = {}) => SocialProjectManager.put(`${resourceName}/atc/datestart/${datestart}/end/${dateend}`, data)

const GetAllByCriteria = (criteria, pagination) => SocialProjectManager.get(`${resourceName}/studentsbycriteria`, {
  params: merge(criteria, pagination)
})

const ExportExcel = criteria => SocialProjectManager.get(`${resourceName}/download-excel`, { params: criteria })
const ExportExcelStudentAbsent = criteria => SocialProjectManager.get(`${resourceName}/download-excel-student-absent`, { params: criteria })

const Search = (criteria, pagination) => SocialProjectManager.get(`${resourceName}`, {
  params: merge(criteria, pagination)
})

const ReportStudentRepository = {
  GetTotalByAge,
  GetTotalByFamilyIncome,
  GetTotalByGender,
  GetTotalByCompany,
  GetTotalBySchool,
  GetTotalByModality,
  GetTotalByLocalTraining,
  GetTotalByDistrict,
  GetStudentsByGender,
  GetStudentsByAge,
  GetStudentsBySchool,
  GetStudentsByModality,
  GetStudentsByLocalTraining,
  GetStudentsByDistrict,
  GetStudentsByFamilyIncome,
  GetAll,
  Search,
  GetStudentsByImc,
  GetByStudentImc,
  GetImcHistoryById,
  GetStudentMeasurementSearched,
  GetTotalBySocialProject,
  GetTotalBySocialProjectId,
  GetAllByCriteria,
  ExportExcel,
  GetAllStudentAbsent,
  ExportExcelStudentAbsent,
  GetATC
}

export default ReportStudentRepository